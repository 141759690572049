import React, { FC, useEffect } from 'react';
import {Route, Routes, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Global } from './constants';
import { ConfigProvider } from 'antd';

import NotFound from './pages/404.page';
import SuspenseWrapper from './hoc/suspense.hoc';
import ProtectedRoute from './hoc/protected-routes.hoc';
import AuthLayout from './components/layouts/auth-layout.component';
import AdminLayout from './components/layouts/admin-layout.component';
import CustomLayout from './components/layouts/custom-layout.component';

import './scss/main.sass';

type Props = {
  isLoggedIn?: any,
}

const App: FC<Props> = ({isLoggedIn}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const DashboardPage = React.lazy(() => import('./pages/admin/dashboard.page'));
  const ProfilePage = React.lazy(() => import('./pages/admin/profile.page'));
  const CompaniesPage = React.lazy(() => import('./pages/admin/branchs.page'));
  const AdminsPage = React.lazy(() => import('./pages/admin/admins.page'));
  const TemplatesPage = React.lazy(() => import('./pages/admin/templates.page'));
  const LoginPage = React.lazy(() => import('./pages/login.page'));
  const DocumentsPage = React.lazy(() => import('./pages/admin/documents.page'));
  const ReportsPage = React.lazy(() => import('./pages/admin/reports.page'));
  const DocumentCreatorPage = React.lazy(() => import('./pages/admin/document-creator.page'));
  const DocumentCreatorPageV2 = React.lazy(() => import('./pages/admin/document-creator-v2.page'));
  const VoboPage =  React.lazy(() => import('./pages/vobo.page'));
  const DocumentDetail =  React.lazy(() => import('./pages/admin/document-detail.page'));
  const ResetPasswordPage = React.lazy(() => import('./pages/reset-password.page'));
  const ForgotPasswordPage = React.lazy(() => import('./pages/forgot-password.page'));
  const GenerateTokenPage = React.lazy(() => import('./pages/generate-token.page'));

  const style = getComputedStyle(document.body);
  const defaultColor = style.getPropertyValue('--theme-color');

  useEffect(() => {
    const unprotectedRoutes = Global.UNPROTECTED_ROUTES;
    const containsUnprotectedRoutes = unprotectedRoutes.filter((path) => location.pathname.startsWith(path));
    if(!isLoggedIn && containsUnprotectedRoutes.length === 0) {
      navigate(Global.APP_URLS.LOGIN); 
    }
  }, [isLoggedIn]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: defaultColor,
        },
      }}
    >
      <SuspenseWrapper>
        <ProtectedRoute route={location.pathname}>
          <>
            <Routes>
              <Route path="/" element={<AuthLayout />}>
                <Route index element={<LoginPage />} />
              </Route>
              <Route path="/auth" element={<AuthLayout />}>
                <Route path="/auth/reset-password" element={<ResetPasswordPage />} />
                <Route path="/auth/forgot" element={<ForgotPasswordPage />} />
                <Route path="/auth/generateToken" element={<GenerateTokenPage/>} />
              </Route>
              <Route path="/admin" element={<AdminLayout />}>
                <Route index element={<DocumentsPage />} />
                <Route path="/admin/profile" element={<ProfilePage />} />
                <Route path="/admin/companies" element={<CompaniesPage />} />
                <Route path="/admin/admins" element={<AdminsPage />} />
                <Route path="/admin/templates" element={<TemplatesPage />} />
                <Route path="/admin/documents" element={<DocumentsPage />} />
                <Route path="/admin/document/creator" element={<DocumentCreatorPage />} />
                <Route path="/admin/document/creator-v2" element={<DocumentCreatorPageV2 />} />
                <Route path="/admin/document/:documentId" element={<DocumentDetail />} />
                <Route path="/admin/reports" element={<ReportsPage />} />
              </Route>
              <Route path="/document" element={<CustomLayout />}>
                <Route path="/document/vobo/:documentId" element={<VoboPage />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </>
        </ProtectedRoute>
      </SuspenseWrapper>
    </ConfigProvider>
  );
};

const mapStateToProps = (state:any) => ({
  isLoggedIn: state.auth?.login || null,
});

export default connect(mapStateToProps, null)(App);
